import { AboutMe } from "../components/AboutMe";
import { Certs } from "../components/certsCarousel/Certs";
import { ContactLink } from "../components/ContactLink";
import { MySkills } from "../components/MySkills";
import { Rights } from "../components/Rights";
import { ScrollToTopBtn } from "../components/ScrollToTopBtn";

export const About = () => {
    return(
        <>
        <AboutMe />
        <MySkills />
        <Certs />
        <ContactLink />
        <Rights />
        <ScrollToTopBtn />
        </>
    )
}