import { MdOutlineArrowOutward } from "react-icons/md";

export const MyWebsite = ({ index, photo, projectName, description, searchTerm, viewLink, activeProject, setActiveProject }) => {
    return <div
    className={`any ${index === activeProject ? "my-project chosen" : "my-project"}`}
    value={index}
    onClick={() => setActiveProject(index)}>
        <a href={viewLink} target="_blank" rel="noreferrer" className="center"><img src={photo} alt="project-photo" className={`any ${index === activeProject ? "projectPhoto shadow" : "projectPhoto"}`} /></a>
        <p className="project-name"><a className={`any ${index === activeProject ? "projectName-link blue" : "projectName-link"}`} href={viewLink} target="_blank" rel="noreferrer">{projectName} <span><MdOutlineArrowOutward className={`any ${index === activeProject ? "arrow" : "arrow up"}`} /></span></a></p>
        <div className="filter-btnGroup">
            {searchTerm.map((item, index) => (
                    <div key={index} className="skills-btn">{item}</div>
            ))}
        </div>
        <p className="project-description">{description}</p>
</div>
}