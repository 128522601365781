import { Link } from "react-router-dom";
import { ImDownload3 } from "react-icons/im";
import resume from "../../assets/resume.pdf";

export const HomePageBtns = () => {
    return <div className="btn-group">
    <Link to="/contacts">
    <button className="cta">Contact me</button>
    </Link>
    <button className="cta"><a className="resume-link" href={resume} download="resume">Resume <ImDownload3 className="download-icon" /></a></button>
    </div>
}