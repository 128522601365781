import { dataOtherProjects } from "../data/dataOtherProjects";
import { MdOutlineArrowOutward } from "react-icons/md";

export const Table = ({ projectHovered, setProjectHovered }) => {
    return(
        <div className="table-container" data-aos="fade-up">
            <table>
                <thead>
                <tr>
                    <th className="table-head">Project</th>
                    <th className="table-head">Built with</th>
                </tr>
                </thead>
                <tbody>
                {dataOtherProjects.map(element => {
                    const { id, project, builtWith, viewLink } = element;
                    return (
                        <tr key={id} 
                        value={id}
                        onMouseEnter={() => setProjectHovered(id)}
                        onMouseLeave={() => setProjectHovered(0)}>
                            <td className="table-data"><a href={viewLink} target="_blank" rel="noreferrer" className={`any ${id === projectHovered ? "projectName-link blue" : "projectName-link"}`}>{project} <span><MdOutlineArrowOutward className={`any ${id === projectHovered ? "projectArrow" : "projectArrow moveUp"}`} /></span></a></td>
                            <td className="table-data filterProjects-btnGroup">{builtWith.map((item, index) => {
                                return(
                                <div key={index} className="skills-btn">{item}</div>
                            )})}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}