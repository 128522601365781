import { dataSocials } from "./data/dataSocials";

export const MySocials = () => {
    return(
        <div className="flex middle">
        {dataSocials.map(social => {
            const {id, socialIcon, socialLink } = social;
            return(
                <div key={id} className="mySocial-container">
                    <a href={socialLink} target="_blank" rel="noreferrer" className="my-social">
                        {socialIcon}
                    </a>
                </div>
            )
        })}
    </div>
    )
}