import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import thanks from "../assets/thanks.png";

export const ContactForm = () => {
    const [state, handleSubmit] = useForm("mldrlqlz");
    if (state.succeeded) {
      return <div className="column response-container">
          <p className="form-response">Thank you for your message.</p>
          <p className="form-response">I will contact you soon.</p>
          <img src={thanks} alt="thanks" className="thanks-icon"/>
          </div>;
    }

    return(
        <form method="POST" onSubmit={handleSubmit}  data-aos="fade-up">
        <div className="form-cover">
      <div className="form-block">
      <input className="input" placeholder="Name" id="name" type="text" name="name" required />
      <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>

      <div className="form-block">
      <input className="input" placeholder="Email" id="email" type="email" name="email" required />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>

      <div className="form-block">
      <textarea className="input textarea" placeholder="Type your message ..." id="message" name="message" required></textarea>
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>

      <div className="center btn-margin">
      <button className="send-btn" type="submit" disabled={state.submitting}>Send</button>
      </div>
      </div>
    </form>
    )
}