import { dataSkills } from "./data/dataSkills";
import { useState } from "react";
import { Skill } from "./Skill";

export const MySkills = () => {
    const [activeSkill, setActiveSkill] = useState(0);

    return(
        <section className="mySkills">
        <div className="center" data-aos="fade-up">
        <h3 className="section-heading"><span>></span> Skills</h3>
        </div>
        <div className="center padding" data-aos="fade-up" data-aos-delay="300">
            {dataSkills.map(skill => {
                const { id, skillsIcon, skillsText } = skill;
                return(
                    <Skill key={id} index={id} skillsIcon={skillsIcon} skillsText={skillsText} activeSkill={activeSkill} setActiveSkill={setActiveSkill} />
                )
            })}
        </div>
    </section>
    )
}