export const dataOtherProjects = [
    {
        id: 1,
        project: "Ten beautiful places",
        builtWith: ["React"],
        viewLink: "https://ten-beautiful-places.netlify.app/"
    },

    {
        id: 2,
        project: "Randomuser API",
        builtWith: ["React", "API"],
        viewLink: "https://my-randomusers-api.netlify.app/"
    },

    {
        id: 3,
        project: "Stopwatch",
        builtWith: ["React"],
        viewLink: "https://react-your-stopwatch.netlify.app/"
    },

    {
        id: 4,
        project: "Dad Jokes API",
        builtWith: ["JavaScript", "API"],
        viewLink: "https://js-dad-jokes-api.netlify.app/"
    },

    {
        id: 5,
        project: "Calculator",
        builtWith: ["JavaScript"],
        viewLink: "https://js-your-calculator.netlify.app/"
    },

    {
        id: 6,
        project: "Gradient Color Generator",
        builtWith: ["JavaScript"],
        viewLink: "https://js-gradient-color-generator.netlify.app/"
    },

    {
        id: 7,
        project: "Dinamic Star Rating",
        builtWith: ["JavaScript"],
        viewLink: "https://js-star-rating.netlify.app/"
    },

    {
        id: 8,
        project: "Advice App",
        builtWith: ["React", "API"],
        viewLink: "https://react-your-advice-app.netlify.app/"
    },

    {
        id: 9,
        project: "To Do List",
        builtWith: ["React"],
        viewLink: "https://to-do-list-for-everyone.netlify.app/"
    },

    {
        id: 10,
        project: "Diamond Clothes",
        builtWith: ["React", "Auth0"],
        viewLink: "https://diamond-clothes.netlify.app/"
    },

    {
        id: 11,
        project: "Password Generator",
        builtWith: ["JavaScript"],
        viewLink: "https://js-easy-password-generator-app.netlify.app/"
    },

    {
        id: 12,
        project: "Captcha Generator",
        builtWith: ["JavaScript"],
        viewLink: "https://js-captcha-generator.netlify.app/"
    },

    {
        id: 13,
        project: "Face Recognition",
        builtWith: ["JavaScript", "API"],
        viewLink: "https://js-face-recognition-app.netlify.app/"
    },

    {
        id: 14,
        project: "Age Calculator",
        builtWith: ["JavaScript"],
        viewLink: "https://js-age-calculator-for-everyone.netlify.app/"
    },

    {
        id: 15,
        project: "Meditation App",
        builtWith: ["JavaScript"],
        viewLink: "https://js-meditation-app.netlify.app/"
    },

    {
        id: 16,
        project: "Text Adventure Game",
        builtWith: ["JavaScript"],
        viewLink: "https://js-text-adventure-game.netlify.app/"
    },

    {
        id: 17,
        project: "Quiz App",
        builtWith: ["JavaScript"],
        viewLink: "https://js-articles-quiz.netlify.app/"
    },

    {
        id: 18,
        project: "Safari Park Countdown",
        builtWith: ["JavaScript"],
        viewLink: "https://js-ogandis-safari.netlify.app/"
    },

    {
        id: 19,
        project: "Incrementing Counter",
        builtWith: ["JavaScript"],
        viewLink: "https://js-automatic-incrementing-counter.netlify.app/"
    },

    {
        id: 20,
        project: "Cool carousel",
        builtWith: ["React"],
        viewLink: "https://react-swiper-carousel.netlify.app/"
    },

    {
        id: 21,
        project: "Expanding Cards",
        builtWith: ["React"],
        viewLink: "https://react-expanding-cards-app.netlify.app/"
    },

    {
        id: 22,
        project: "Daily Predictions App",
        builtWith: ["JavaScript"],
        viewLink: "https://your-daily-predictions.netlify.app/"
    }
]