import { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const isOpen = isMenuOpen ? "open" : "";

    return <header>
    <div className={`menu-toggle ${isOpen}`} onClick={toggleMenu}>
        <div className="hamburger">
        <svg viewBox="0 0 32 32">
                <path
                  className="line line-top-bottom"
                  d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                ></path>
                <path className="line" d="M7 16 27 16"></path>
              </svg>
    </div>
    </div>
    <div className={`background ${isOpen}`}></div>
    <nav className={`navbar ${isOpen}`}>
    <Link to="/" className={`my-name ${isMenuOpen ? "appear" : ""}`}
              style={{animationDelay: `0.10s`}}
              onClick={toggleMenu}>
    NM
    </Link>
    <ul className="nav-list">
      <li>
        <Link to="/about" className={`nav-link ${isMenuOpen ? "appear" : ""}`}
              style={{animationDelay: `0.25s`}}
              onClick={toggleMenu}>
          About
        </Link>
      </li>
      <li>
        <Link to="/projects" className={`nav-link ${isMenuOpen ? "appear" : ""}`}
              style={{animationDelay: `0.40s`}}
              onClick={toggleMenu}>
        Projects
        </Link>
      </li>
      <li>
        <Link to="/contacts" className={`nav-link ${isMenuOpen ? "appear" : ""}`}
              style={{animationDelay: `0.55s`}}
              onClick={toggleMenu}>
        Contacts
        </Link>
      </li>
    </ul>
  </nav>
  </header>
}