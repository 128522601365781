import { ContactLink } from "../components/ContactLink";
import { ProjectsHeader } from "../components/ProjectsHeader";
import { Rights } from "../components/Rights";
import { ScrollToTopBtn } from "../components/ScrollToTopBtn";
import { Websites } from "../components/Websites/Websites";
import { Applications } from "../components/Applications/Applications";
import { ViewOtherProjects } from "../components/OtherProjects/ViewOtherProjects";

export const Projects = () => {
    return(
        <>
        <section className="myProjects">
            <ProjectsHeader />
            <Websites />
            <Applications />
        </section>
        <ViewOtherProjects />
        <ContactLink />
        <Rights />
        <ScrollToTopBtn />
        </>
    )
}