import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import './Certs.css';
import { EffectCreative, Autoplay } from 'swiper/modules';
import { dataCertificates } from './dataCertificates';

export const Certs = () => {
  return (
    <div className="certs-background">
        <div className="certs-cover center">
    <div className="carousel-container">
      <Swiper
        grabCursor={true}
        loop={true}
        autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[EffectCreative, Autoplay]}
        className="mySwiper"
      >
        {dataCertificates.map((slide) => (
                <SwiperSlide key={slide.id} 
                style={{
                    backgroundImage: `url(${slide.cert})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                >
                </SwiperSlide>
            )
          )}
      </Swiper>
    </div>
    </div>
    </div>
  );
}