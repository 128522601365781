import one from "../../assets/projects/your-fit.png";
import two from "../../assets/projects/web-doc.png";
import three from "../../assets/projects/wando.png";
import four from "../../assets/projects/movie-searcher.png";
import five from "../../assets/projects/malato.png";
import six from "../../assets/projects/beauty.png";
import seven from "../../assets/projects/furniture.png";
import eight from "../../assets/projects/photo.png";

export const dataWebsites = [
    {
        id: 1,
        photo: one,
        projectName: "Your Fit",
        description: "Full-stack multi-page fitness website with a shopping cart, payment system (Stripe), real-time review system, contact form, carousels (Swiper JS, React Slick), modal, loading, tabs, accordions, MDB 5 footer, Material UI checkbox, react-icons, React tooltip, counter functionality, Sweetalert2, React Bootstrap. With fully responsive design, GSAP Scroll Trigger animation and React Router DOM.",
        searchTerm: ["React", "Redux", "Node", "Express", "MongoDB", "Mongoose", "Bootstrap"],
        viewLink: "https://react-your-fit.netlify.app/"
    },

    {
        id: 2,
        photo: two,
        projectName: "WebDoc",
        description: "Fully responsive online doctors website with Cookies and personal account. Built with React using React Router DOM, React Hooks, React Awesome Reveal for animation, integration data.js files, Auth0 for personal account, Bootstrap carousel, react-responsive-carousel, react-icons, Sweetalert2, counter functionality, Formspree for a contact form, accordion, loader, MDB5 footer and tabs, Cookie Consent, bottom nav.",
        searchTerm: ["React", "Bootstrap"],
        viewLink: "https://react-web-doc.netlify.app/"
    },

    {
        id: 3,
        photo: three,
        projectName: "Wando Sushi",
        description: "Full-stack sushi restaurant website featuring a shopping cart with options to adjust quantities, remove items, clear the cart or proceed with payment using Stripe. With fully responsive design and GSAP Scroll Trigger animation. Features Swiper vertical carousel, menu filter buttons, tabs, modal, loader, react-icons, MDB5 footer, Formspree for subscriptions, React Router DOM.",
        searchTerm: ["React", "Redux", "Node", "Express"],
        viewLink: "https://wando-sushi.netlify.app/"
    },

    {
        id: 4,
        photo: four,
        projectName: "Movie Searcher",
        description: "Movie searcher website featuring a cool Swiperjs slider, an input field with convenient search function, movie cards with an attractive hover effect, links with movies descriptions and trailers. Fully responsive, with interactive animation and `back-to-top` button.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-movie-searcher.netlify.app/"
    },

    {
        id: 5,
        photo: five,
        projectName: "Malato Ice Cream",
        description: "Ice cream shop website with responsive design, interactive animations (GSAP and (AOS (animate-on-scroll library)), parallax effects and embedded Google Maps.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://malato-ice-cream.netlify.app/"
    },

    {
        id: 6,
        photo: six,
        projectName: "Beauty & Glory",
        description: "Beauty studio website built with Bootstrap framework and AOS(animate-on-scroll library). Fully responsive, with a carousel, a contact form, a table and embedded Google Maps.",
        searchTerm: ["HTML", "CSS", "Bootstrap"],
        viewLink: "https://beauty-and-glory.netlify.app/"
    },

    {
        id: 7,
        photo: seven,
        projectName: "Sweet Dreams",
        description: "Modern furniture shop website featuring expanding cards with text, a simple slider, a countdown timer, a parallax effect, a contact form. With responsive design and nice animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://sweet-dream-furniture.netlify.app/"
    },

    {
        id: 8,
        photo: eight,
        projectName: "Photographer Website",
        description: "Photographer website built with Bootstrap, featuring carousels, prices and reviews sections, interactive animation and responsive design.",
        searchTerm: ["HTML", "CSS", "Bootstrap"],
        viewLink: "https://your-fav-photo.netlify.app/"
    }
]