import { RiLinkedinFill } from "react-icons/ri";
import { SiGithub } from "react-icons/si";
import { RiInstagramFill } from "react-icons/ri";
import { SiFacebook } from "react-icons/si";
import { SiWhatsapp } from "react-icons/si";

export const dataSocials = [
    {
        id: 1,
        socialIcon: <RiLinkedinFill />,
        socialLink: "https://www.linkedin.com/in/natalia-musikhina/"
    },

    {
        id: 2,
        socialIcon: <SiGithub />,
        socialLink: "https://github.com/Natalia605207"
    },

    {
        id: 3,
        socialIcon: <RiInstagramFill />,
        socialLink: "https://www.instagram.com/natalia_musikhina_webdev/"
    },

    {
        id: 4,
        socialIcon: <SiFacebook />,
        socialLink: "https://www.facebook.com/profile.php?id=61556536869804&mibextid=ZbWKwL"
    },

    {
        id: 5,
        socialIcon: <SiWhatsapp />,
        socialLink: "https://wa.me/+14378780400"
    },
]