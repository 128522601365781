import { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowForward } from "react-icons/md";

export const ViewOtherProjects = () => {
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

    return(
        <div className="otherProjects-background">
            <div className="otherProjects-cover center">
                <div className="center glass-bg"
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}>
                    <Link to="/otherProjects" className={`any ${hovered === true ? "projectName-link blue" : "projectName-link"}`}>
                        <p className="otherProjects-link">View other projects</p>
                    </Link>
                    <p className="arrow-container"><MdOutlineArrowForward className={`any ${hovered === true ? "straight-arrow forward" : "straight-arrow"}`} /></p>
                </div>
            </div>
        </div>
    )
}