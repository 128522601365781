import { AboutMeDetails } from "./AboutMeDetails";
import { MyPhoto } from "./MyPhoto";

export const AboutMe = () => {
    return(
        <section className="about">
        <div className="row">
            <MyPhoto />
            <AboutMeDetails />
        </div>
        </section>
    )
}