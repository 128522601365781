import { useState } from "react";
import { dataWebsites } from "../data/dataWebsites";
import { WebsitesButtons } from "./WebsitesButtons";
import { WebsitesInfo } from "./WebsitesInfo";
import infinity from "../../assets/projects/infinity.png";

export const Websites = () => {
    const [myWebsites, setMyWebsites] = useState(dataWebsites);

    const filterWebsites = (searchTerm) => {
        const chosenWebsites = dataWebsites.filter(website => website.searchTerm.includes(searchTerm) === true);
        setMyWebsites(chosenWebsites);
    }

    return(
        <div className="column">
        <h4 className="projects-subheading" data-aos="fade-up"><span><img src={infinity} alt="infinity" className="infinity-icon" /></span>Websites</h4>
        <WebsitesButtons filterWebsites={filterWebsites} setMyWebsites={setMyWebsites} dataWebsites={dataWebsites} />
        <WebsitesInfo myWebsites={myWebsites} />
    </div>
    )
}