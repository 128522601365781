import { IntroductionWords } from '../components/HomePage/IntroductionWords';
import { BottomInfo } from '../components/HomePage/BottomInfo';
import "../App.css";
import { HomePageIcons } from '../components/HomePage/HomePageIcons';

export const Home = () => {
    return (
        <main className="main-container">
            <IntroductionWords />
            <BottomInfo />
            <HomePageIcons />
        </main>
    );
}