import { Link } from "react-router-dom";
import { MySocials } from "./MySocials";

export const ContactLink = () => {
    return(
        <section className="contact-link details">
            <div className="space-between wrap">
                <div className="details">
                    <p className="offer">Want to see me in your team?</p>
                    <p className="or">or</p>
                    <p className="offer">Ready to build your perfect website?</p>
                </div>
                <Link to="/contacts">
                    <button className="cta">Contact me</button>
                </Link>
            </div>
            <MySocials />
        </section>
    )
}