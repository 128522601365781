import { MySocials } from "./MySocials";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import sendIcon from "../assets/send.png";

export const ContactDetails = () => {
    return(
        <div className="details"  data-aos="fade-up">
        <img src={sendIcon} className="sendIcon" alt="send" />
        <div className="flex flexWrap">
        <MdEmail className="contactMe-icon" />
        <p className="my-info more-space"><a className="white" rel="noreferrer" href="mailto:natalia.musikhina.webdev@gmail.com">natalia.musikhina.webdev@gmail.com</a></p>
        </div>
        <div className="flex">
        <MdLocationOn className="contactMe-icon" />
        <p className="my-info more-space">Toronto, CA</p>
        </div>
        <MySocials />
        </div>
    )
}