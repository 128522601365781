import nine from "../../assets/projects/meal-planMERN.png";
import ten from "../../assets/projects/qrCode-generator.png";
import eleven from "../../assets/projects/to-doMERN.png";
import twelve from "../../assets/projects/recipe-finder-API.png";
import thirteen from "../../assets/projects/nutrition-analysis.png";
import fourteen from "../../assets/projects/weatherApi.png";
import fifteen from "../../assets/projects/piano.png";
import sixteen from "../../assets/projects/daily-mealPlan.png";
import seventeen from "../../assets/projects/easy-timer.png";
import eighteen from "../../assets/projects/stopwatch.png";
import nineteen from "../../assets/projects/christmas-countdown.png";
import twenty from "../../assets/projects/memory-game.png";
import twentyOne from "../../assets/projects/bill-splitter.png";
import twentyTwo from "../../assets/projects/calorie-calculator.png";
import twentyThree from "../../assets/projects/healthy-salads.png";
import twentyFour from "../../assets/projects/gst-hst.png";
import twentyFive from "../../assets/projects/daily-quote.png";
import twentySix from "../../assets/projects/human-computer.png";
import twentySeven from "../../assets/projects/shopping-list.png";

export const dataApplications = [
    {
        id: 9,
        photo: nine,
        projectName: "Meal Plan",
        description: "Full-stack MERN Meal Plan application which lets users perform CRUD (create, read, update and delete) operations. You can create notes with food you are planning to eat, make changes in already existing notes and delete them. All data is stored in MongoDB database. With responsive design and GSAP for smooth animation.",
        searchTerm: ["React", "Node", "Express", "MongoDB", "Mongoose"],
        viewLink: "https://fullstack-mern-meal-plan.netlify.app/"
    },

    
    {
        id: 10,
        photo: ten,
        projectName: "QR Code Generator",
        description: "Web App built with QR code generator web API that transforms any phrase entered in the input field into a QR Code. A user can adjust the QR Code size and background color and then, download and decode/read QR code graphics. Fully responsive. Framer Motion is used for animation.",
        searchTerm: ["React", "API"],
        viewLink: "https://react-qrcode-generator-for-you.netlify.app/"
    },

    
    {
        id: 11,
        photo: eleven,
        projectName: "To Do List",
        description: "Full-stack To Do List application with responsive design that lets users create, correct and delete tasks. The data is stored in MongoDB database.",
        searchTerm: ["React", "Node", "Express", "MongoDB", "Mongoose"],
        viewLink: "https://fullstack-mern-to-do-list.netlify.app/"
    },

    
    {
        id: 12,
        photo: twelve,
        projectName: "Recipe Search App",
        description: "Web App built with Recipe Search API that shows 20 recipes with ingredients, total nutrients and total weight information upon request. With fully responsive design and GSAP animation.",
        searchTerm: ["React", "API"],
        viewLink: "https://react-daily-recipes-app.netlify.app/"
    },

    
    {
        id: 13,
        photo: thirteen,
        projectName: "Nutrition Analysis App",
        description: "Web App built with Nutrition Analysis API that helps to analyze the meal. Enumerate the meal in the input filed and get Ingredients information as well as Nutrition facts per serving. With loader, SweetAlert2, GSAP animation and responsive design.",
        searchTerm: ["React", "API"],
        viewLink: "https://react-nutrition-analysis-app.netlify.app/"
    },

    
    {
        id: 14,
        photo: fourteen,
        projectName: "Weather App",
        description: "Fully responsive Weather App built with Weather API. See the weather in any place of the world in a real time. Shows the following data: city, country, today's date, real temperature, feels like temperature, weather conditions, wind speed, humidity. With interactive AOS (Animate On Scroll Library) animation.",
        searchTerm: ["HTML", "CSS", "JavaScript", "API"],
        viewLink: "https://weather-app-real-time.netlify.app/"
    },

    
    {
        id: 15,
        photo: fifteen,
        projectName: "Piano App",
        description: "Web App that lets users play the piano, adjust the volume and switch the piano keys visability. With fully responsive design and interactive AOS (Animate On Scroll Library) animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-your-piano.netlify.app/"
    },

    
    {
        id: 16,
        photo: sixteen,
        projectName: "Daily Meal Plan",
        description: "Web App that helps users to create daily meal plans. Just add a day and fill in the necessary information. Users can change the information in each daily note or delete a day note. The data is stored in localStorage. With responsive design and GSAP animation.",
        searchTerm: ["React", "localStorage"],
        viewLink: "https://react-daily-meal-plan.netlify.app/"
    },

    
    {
        id: 17,
        photo: seventeen,
        projectName: "Easy Timer",
        description: "Web App that lets users set the desired time and start the countdown timer. The timer can be cleared with a separate button. When the time is up, the sound signal is performed. With responsive design, smooth GSAP animation and SweetAlert2.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-easy-timer.netlify.app/"
    },

    
    {
        id: 18,
        photo: eighteen,
        projectName: "Stopwatch App",
        description: "Stopwatch App with start, pause, reset and lap buttons. Users can delete each lap separately or use a button to clear all laps at once. With AOS (Animate On Scroll Library) animation and responsive design.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-stopwatch-with-laps.netlify.app/"
    },

    
    {
        id: 19,
        photo: nineteen,
        projectName: "Christmas Countdown",
        description: "Web App that shows how many days, hours, minutes and seconds are left untill Christmas. Particles.js library is used to create the effect of falling snow. With responsive design and Animista for animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-christmas-countdown-with-snow.netlify.app/"
    },

    
    {
        id: 20,
        photo: twenty,
        projectName: "Memory Game",
        description: "Fully responsive Memory Game application with flip cards effect and AOS (Animate On Scroll Library) animation. Find 2 cards that match and open 8 pairs of cards. Then, start the game again.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-memory-game-app.netlify.app/"
    },

    {
        id: 21,
        photo: twentyOne,
        projectName: "Bill Splitter",
        description: "Web App that helps to split the bill costs equally among all payers. Enter bill amount, number of people and tips and see splitted costs per person. Fully responsive, with SweetAlert2 and GSAP animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-bill-splitter.netlify.app/"
    },

    {
        id: 22,
        photo: twentyTwo,
        projectName: "Calorie Calculator",
        description: "Web App that helps to calculate your daily calorie intake based on gender, age, weight, height and activity level parameter. Fully responsive, with SweetAlert2 and GSAP animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-calorie-calculator.netlify.app/"
    },

    {
        id: 23,
        photo: twentyThree,
        projectName: "Healthy Salads",
        description: "Multi-page Web App containing 3 salads recipes with coocking timers, ingredient lists ans coocking instructions. With AOS (Animate On Scroll Library) animation and responsive design.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-healthy-salads-recipes.netlify.app/"
    },

    {
        id: 24,
        photo: twentyFour,
        projectName: "Canada GST/HST Calculator",
        description: "Web App that helps to calculate the amount of taxes that applies to sales in Canada. The second tab shows us reverse GST/HST calculator. Fully responsive, with SweetAlert2 and GSAP animation.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-gst-hst-calculator.netlify.app/"
    },

    {
        id: 25,
        photo: twentyFive,
        projectName: "Daily Quotes",
        description: "Easy to use Daily Quotes app with responsive design and GSAP animation. Just click the button and get a random quote and a random background photo.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-quote-of-the-day.netlify.app/"
    },

    {
        id: 26,
        photo: twentySix,
        projectName: "Human vs Computer",
        description: "Web App where you should guess a number from 1 to 30 calculated randomly by computer. With AOS (Animate On Scroll Library) animation, SweetAlert2 and responsive design.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://iron-and-human-brain.netlify.app/"
    },

    {
        id: 27,
        photo: twentySeven,
        projectName: "Shopping List",
        description: "Shopping List App helps to make a list of things you would like to buy. Click on an item one time and cross it out. Click twice and remove the item. With AOS (Animate On Scroll Library) animation and responsive design.",
        searchTerm: ["HTML", "CSS", "JavaScript"],
        viewLink: "https://js-shopping-list-app.netlify.app/"
    }
]