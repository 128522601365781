import { ContactMe } from "../components/ContactMe"
import { Rights } from "../components/Rights"

export const Contacts = () => {
    return(
        <>
        <ContactMe />
        <Rights />
        </>
    )
}