import { useState } from "react";
import { dataApplications } from "../data/dataApplications";
import { AppButtons } from "./AppButtons";
import { AppInfo } from "./AppInfo";
import infinity from "../../assets/projects/infinity.png";

export const Applications = () => {
    const [myApps, setMyApps] = useState(dataApplications);

    const filterApps = (searchTerm) => {
        const chosenApps = dataApplications.filter(app => app.searchTerm.includes(searchTerm) === true);
        setMyApps(chosenApps);
    }

    return(
        <div className="column">
        <h4 className="projects-subheading"><span><img src={infinity} alt="infinity" className="infinity-icon" /></span>Applications</h4>
        <AppButtons filterApps={filterApps} setMyApps={setMyApps} dataApplications={dataApplications} />
        <AppInfo myApps={myApps} />
    </div>
    )
}