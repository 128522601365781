import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { HomePageBtns } from "./HomePageBtns";

export const IntroductionWords = () => {
    return(
        <div className="intoduction-words" 
        data-aos="fade-right"
        data-aos-delay="500">
        <h3 className="first">Hello! I am</h3>
        <h2 className="second">Natalia Musikhina</h2>
        <h3 className="third">
        A {" "}
        <ReactTyped
          strings={["Fullstack developer", "Freelancer"]}
          typeSpeed={100}
          startDelay={1000}
          loop
          backSpeed={20}
          cursorChar="|"
          showCursor={true}
        />
        </h3>
        <p className="forth">I love creating websites and applications. Check out my latest works on the <Link to="/projects" className="projects-link">Projects Page</Link>.</p>
        <HomePageBtns />
        </div>
    )
}