import { useState } from "react";
import { MyApp } from "./MyApp";

export const AppInfo = ({ myApps }) => {
    const [activeProject, setActiveProject] = useState(0);

    return(
        <div className="projects-container">
            {myApps.map(element => {
                const { id, photo, projectName, description, searchTerm, viewLink } = element;
                return(
                    <MyApp key={id} 
                    index={id} 
                    photo={photo} 
                    projectName={projectName} 
                    description={description} 
                    searchTerm={searchTerm} 
                    viewLink={viewLink} 
                    activeProject={activeProject} 
                    setActiveProject={setActiveProject} />
                )
            })}
        </div>
    )
}