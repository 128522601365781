import code from "../../assets/coding_icons/code.png";
import cloud from "../../assets/coding_icons/bulb.png";
import network from "../../assets/coding_icons/features.png";

export const HomePageIcons = () => {
    return <div>
        <img src={code} alt="codeIcon" className="frontIcon code" />
        <img src={cloud} alt="codeIcon" className="frontIcon cloud" />
        <img src={network} alt="codeIcon" className="frontIcon network" />
    </div>
}