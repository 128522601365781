import certjs from "../../assets/certificates/Cert_HTML_CSS_JS.png";
import certbootstrap from "../../assets/certificates/Cert_Bootstrap5.jpg";
import certfigma from "../../assets/certificates/Cert_GSAP_FIGMA.jpg";
import certreact from "../../assets/certificates/CERT_REACT_REDUX.png";
import certnode from "../../assets/certificates/NODE_CERT.png";

export const dataCertificates = [
    {
        id: 1,
        cert: certjs
    },

    {
        id: 2,
        cert: certbootstrap
    },

    {
        id: 3,
        cert: certfigma
    },

    {
        id: 4,
        cert: certreact
    },

    {
        id: 5,
        cert: certnode
    }
]