import { ContactDetails } from "./ContactDetails";
import { ContactForm } from "./ContactForm";

export const ContactMe = () => {
    return(
        <section className="myContacts">
        <div className="column">
        <h3 className="section-heading" data-aos="fade-right"><span>></span> Contact Me</h3>
        <p className="subheading" data-aos="fade-right">I am always open to new projects or job discussion.</p>
        </div>
        <div className="contacts-container">
            <ContactForm />
            <ContactDetails />
        </div>
        </section>
    )
}