export const AppButtons = ({ filterApps, setMyApps, dataApplications }) => {
    return (
      <div className="filter-btnGroup">
      <button className="filteredProjects-btn" onClick={() => setMyApps(dataApplications)}>View All</button>
      <button className="filteredProjects-btn" onClick={() => filterApps("JavaScript")}>JavaScript</button>
      <button className="filteredProjects-btn" onClick={() => filterApps("React")}>React</button>
      <button className="filteredProjects-btn" onClick={() => filterApps("API")}>API</button>
      <button className="filteredProjects-btn" onClick={() => filterApps("Node")}>Node</button>
      <button className="filteredProjects-btn" onClick={() => filterApps("MongoDB")}>MongoDB</button>
      </div>
    );
  }