export const dataAboutMe = [
    {
        id: 1,
        phrase: "A Fullstack web developer from Toronto, Canada."
    },

    {
        id: 2,
        phrase: "Built 50+ responsive, user-friendly applications and websites."
    },

    {
        id: 3,
        phrase: "Currently improving my skills in MERN stack."
    },

    {
        id: 4,
        phrase: "Plan to learn Flutter and Python."
    },

    {
        id: 5,
        phrase: "Enthusiastic about keeping up with the latest technologies and techniques through continuous learning."
    }
]