import html from "../../assets/skill_icons/html.png";
import css from "../../assets/skill_icons/css.png";
import js from "../../assets/skill_icons/javascript.png";
import reactIcon from "../../assets/skill_icons/react.png";
import nodeIcon from "../../assets/skill_icons/nodejs.png";
import mongo from "../../assets/skill_icons/mongodb.png";
import redux from "../../assets/skill_icons/redux.png";
import express from "../../assets/skill_icons/express.png";
import mongoose from "../../assets/skill_icons/mongoose.png";
import postman from "../../assets/skill_icons/postman.png";
import npm from "../../assets/skill_icons/npm.png";
import git from "../../assets/skill_icons/git.png";
import github from "../../assets/skill_icons/github.png";
import bootstrap from "../../assets/skill_icons/bootstrap.png";
import figma from "../../assets/skill_icons/figma.png";
import gsap from "../../assets/skill_icons/gsap.png";

export const dataSkills = [
    {
        id: 1,
        skillsIcon: html,
        skillsText: "HTML"
    },

    {
        id: 2,
        skillsIcon: css,
        skillsText: "CSS"
    },

    {
        id: 3,
        skillsIcon: js,
        skillsText: "JavaScript"
    },

    {
        id: 4,
        skillsIcon: reactIcon,
        skillsText: "React"
    },

    {
        id: 5,
        skillsIcon: redux,
        skillsText: "Redux"
    },

    {
        id: 6,
        skillsIcon: nodeIcon,
        skillsText: "Node.js"
    },

    {
        id: 7,
        skillsIcon: express,
        skillsText: "Express"
    },

    {
        id: 8,
        skillsIcon: mongo,
        skillsText: "MongoDB"
    },

    {
        id: 9,
        skillsIcon: mongoose,
        skillsText: "Mongoose"
    },

    {
        id: 10,
        skillsIcon: postman,
        skillsText: "Postman"
    },

    {
        id: 11,
        skillsIcon: npm,
        skillsText: "npm"
    },

    {
        id: 12,
        skillsIcon: git,
        skillsText: "Git"
    },

    {
        id: 13,
        skillsIcon: github,
        skillsText: "GitHub"
    },

    {
        id: 14,
        skillsIcon: bootstrap,
        skillsText: "Bootstrap"
    },

    {
        id: 15,
        skillsIcon: gsap,
        skillsText: "GSAP"
    },

    {
        id: 16,
        skillsIcon: figma,
        skillsText: "Figma"
    }
]