import {useState} from "react";
import { OtherProjectsHeader } from "../components/OtherProjects/OtherProjectsHeader";
import { Table } from "../components/OtherProjects/Table";
import { ContactLink } from "../components/ContactLink";
import { Rights } from "../components/Rights";
import { ScrollToTopBtn } from "../components/ScrollToTopBtn";

export const OtherProjectsTable = () => {
    const [projectHovered, setProjectHovered] = useState(0);

    return(
        <>
        <section className="otherProjects">
            <OtherProjectsHeader />
            <Table projectHovered={projectHovered} setProjectHovered={setProjectHovered} />
        </section>
        <ContactLink />
        <Rights />
        <ScrollToTopBtn />
        </>
    )
}