export const WebsitesButtons = ({ filterWebsites, setMyWebsites, dataWebsites }) => {
    return (
      <div className="filter-btnGroup" data-aos="fade-up">
      <button className="filteredProjects-btn" onClick={() => setMyWebsites(dataWebsites)}>View All</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("JavaScript")}>JavaScript</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("React")}>React</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("Redux")}>Redux</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("Bootstrap")}>Bootstrap</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("Node")}>Node</button>
      <button className="filteredProjects-btn" onClick={() => filterWebsites("MongoDB")}>MongoDB</button>
      </div>
    );
  }