import { Link } from "react-router-dom";
import { dataAboutMe } from "../components/data/dataAboutMe";
import { TiArrowForwardOutline } from "react-icons/ti";
import rocket from "../assets/rocket.png";

export const AboutMeDetails = () => {
    return (
        <div className="details" data-aos="fade-up">
        <h3 className="aboutMe-heading"><span>></span> Hi there! My name is <span className="myName">Natalia</span>.</h3>
            {dataAboutMe.map(point => {
                const { id, phrase } = point;
                return(
                    <div key={id} className="flex">
                    <p className="my-facts"> <TiArrowForwardOutline className="right-arrow" /> {phrase}</p>
                    </div>
                )
            })}
            <div className="margin-top">
                <Link to="/projects"><button className="cta">View my projects</button></Link>
            </div>
            <img src={rocket} alt="rocket" className="rocket-icon" />
    </div>
    )
}